define(['app', '$console', 'siteObj', '$window', 'accessibilityFocusHelper', 'accessibleModalHelper'], (app, $console, siteObj, $window, accessibilityFocusHelper, accessibleModalHelper) => () => {
  const ZOOM_LEVEL = 3;

  const _channels = {
    updateImageChannel: 'productMultipleImages/newImage',
    updateImageOverlayChannel: 'productCarouselImageOverlay/update',
    previewPersonalisation: 'personalisation/preview',
  };

  const _selectors = {
    leftArrow: '.athenaProductImageCarousel_leftArrow',
    rightArrow: '.athenaProductImageCarousel_rightArrow',
    thumbnail: '.athenaProductImageCarousel_thumbnail',
    thumbnailWrapper: '.athenaProductImageCarousel_thumbnailWrapper',
    imageThumbnailWrapper: '[data-thumbnail-type="image"]',
    videoThumbnailWrapper: '[data-thumbnail-type="video"]',
    imageWrapper: '.athenaProductImageCarousel_imageWrapper',
    imageSlider: '.athenaProductImageCarousel_imageSlider',
    imagePreview: '.athenaProductImageCarousel_imagePreview',
    image: '.athenaProductImageCarousel_image',
    video: '.videoPlayer',
    videoContainer: '.videoPlayer_container',
    zoom: '.athenaProductImageCarousel_zoom',
    path: '[data-path]',
    imageContainer: '.athenaProductImageCarousel_imagesContainer',
    thumbnailContainer: '.athenaProductImageCarousel_thumbnailScrollContainer',
    component: '[data-component="athenaProductImageCarousel"]',
    openTranscriptButton: '.videoPlayer_transcriptButton',
    progressIndicator: '.athenaProductImageCarousel_progressIndicator',
    imageProgressIndicator: '[data-thumbnail-type="image-indicator"]',
    videoProgressIndicator: '[data-thumbnail-type="video-indicator"]',
    // previewPersonalisation: '.personalisation_ScrollButton',
  };

  const _attr = {
    active: 'data-active',
    index: 'data-index',
    path: 'data-path',
    size: 'data-size',
    hide: 'data-hide',
    dataAlt: 'data-alt',
  };

  const _touch = {
    history: [],
    historyMaxLength: 5,
    threshold: 1,
  };

  const comp = {
    element: null,
    slider: null,
    previousButton: null,
    nextButton: null,
    zoomButton: null,
    carouselImageContainer: null,
    carouselThumbnailContainer: null,
    previewPersonalisationButton: null,
    selection: 0,
    thumbnails: [],
    thumbnailWrappers: [],
    progressIndicators: [],
    wrappers: [],
    images: [],
    _touch,
    _attr,
  };

  comp.init = function (element, manualCall) {
    comp.element = element;
    comp.slider = element.querySelector(_selectors.imageSlider);
    comp.previousButton = element.querySelector(_selectors.leftArrow);
    comp.nextButton = element.querySelector(_selectors.rightArrow);
    comp.zoomButton = element.querySelector(_selectors.zoom);
    comp.carouselImageContainer = element.querySelector(_selectors.imageContainer);
    comp.carouselThumbnailContainer = element.querySelector(_selectors.thumbnailContainer);
    comp.thumbnails = [...element.querySelectorAll(_selectors.thumbnail)];
    comp.thumbnailWrappers = [...element.querySelectorAll(_selectors.thumbnailWrapper)];
    comp.imageThumbnailWrappers = [...element.querySelectorAll(_selectors.imageThumbnailWrapper)];
    comp.videoThumbnailWrapper = element.querySelector(_selectors.videoThumbnailWrapper);
    comp.video = element.querySelector(_selectors.video);
    comp.videoContainer = element.querySelector(_selectors.videoContainer);
    comp.wrappers = [...element.querySelectorAll(_selectors.imageWrapper)];
    comp.progressIndicators = [...element.querySelectorAll(_selectors.progressIndicator)];
    comp.imageProgressIndicators = [...element.querySelectorAll(_selectors.imageProgressIndicator)];
    comp.videoProgressIndicator = element.querySelector(_selectors.videoProgressIndicator);

    // Transcript elements
    comp.openTranscriptButton = element.querySelector(_selectors.openTranscriptButton);

    comp.images = comp.wrappers.map((wrapper, index) => {
      const type = wrapper.getAttribute('data-type') === 'video' ? 'video' : 'image';
      const element = type === 'image' ?
        wrapper.querySelector(_selectors.image) : wrapper.querySelector(_selectors.video);
      const previewEl = wrapper.querySelector(_selectors.imagePreview);
      const images = [...wrapper.querySelectorAll(_selectors.path)];

      // filter unknown sizes and map to srcset
      const tmpImages =
        images
          .map(image => {
            const path = image.getAttribute(_attr.path);
            const size = parseInt(image.getAttribute(_attr.size));

            return {path, size};
          })
          .filter(image => !isNaN(image.size) && image.size > 0);

      const src = (() => {
        const sizes = tmpImages.map(({size}) => size);
        if (sizes.length > 0) {
          const largestSize = Math.max(...sizes);
          const largestImage = tmpImages.find(({size}) => largestSize === size);
          return largestImage ? largestImage.path : '';
        } else {
          return '';
        }
      })();
      const srcset = tmpImages.map(image => `${image.path} ${image.size}w`).join(', ');
      const zoomedSrcset = tmpImages.map(image => `${image.path} ${Math.round(image.size / ZOOM_LEVEL)}w`).join(', ');

      const thumbnailSrc = type === 'image' ? comp.thumbnails[index].src : '';
      if(!manualCall){
        app.subscribe(_channels.previewPersonalisation, comp.previewPersonalisation);
      }
      
      return {
        loaded: false,
        thumbnail: thumbnailSrc,
        type: type,
        element,
        previewEl,
        src,
        srcset,
        zoomedSrcset,
      };
    });

    comp.toggleArrows();
    comp.bind();
    comp.navigate(0, true);
    comp.setThumbnailHeight();

    comp.setAccessibleElementNames(comp.progressIndicators, comp.videoProgressIndicator);
    comp.setAccessibleElementNames(comp.imageThumbnailWrappers, comp.videoThumbnailWrapper);

    $window.addEventListener('optimizedResize', comp.setThumbnailHeight, false);

    _throttle('resize', 'optimizedResize');

    if (!manualCall) {
      app.subscribe(_channels.updateImageChannel, comp.updateImage);
    }
  };

  comp.toggleArrows = function () {
    if (comp.images.length < 2) {
      return;
    }

    comp.previousButton.classList.add('show');
    comp.nextButton.classList.add('show');
  };

  comp.setThumbnailHeight = function () {
    const height = getComputedStyle(comp.carouselImageContainer).height;
    if (window.innerWidth > 900) {
      if (height !== undefined && height !== '0px' && height !== '0') {
        comp.carouselThumbnailContainer.style.height = height;
      }
    } else {
      comp.carouselThumbnailContainer.style.height = 'auto';
    }
  };

  comp.setAccessibleElementNames = (accessibleElements, accessibleVideo) => {
    accessibleElements.forEach((accessibleEl, index) => {
      const accessibleName = accessibleEl.getAttribute('aria-label').replace('-', `${index + 1} -`);
      accessibleEl.setAttribute('aria-label', accessibleName);
      accessibleEl.setAttribute('title', accessibleName);
    });

    if(accessibleVideo && comp.video){
      const videoARIALabel = comp.video.getAttribute('aria-label');
      videoARIALabel && accessibleVideo.setAttribute('aria-label', videoARIALabel);
    }
  };

  comp.bind = function () {
    comp.previousButton.addEventListener('click', comp.previous);
    comp.nextButton.addEventListener('click', comp.next);
    comp.zoomButton.addEventListener('click', comp.zoomClick);
    comp.slider.addEventListener('touchstart', comp.touchstart);
    comp.slider.addEventListener('touchmove', comp.touchmove);
    comp.slider.addEventListener('touchend', comp.touchend);

    comp.thumbnailWrappers.map((thumbnail, index) => {
      thumbnail._index = index;
      thumbnail.addEventListener('click', comp.thumbnailClick);
    });

    comp.progressIndicators.map((indicator, index) => {
      indicator._index = index;
      indicator.addEventListener('click', comp.thumbnailClick);
    });

    comp.images.map((image, index) => {
      image.element._index = index;
    });
  };

  comp.updateImage = function ({productId, variation, personalised, templateName=''}) {
    productId = productId || siteObj.productId;

    app.ajax.get({
      url: `/${productId}.images?variation=${variation}&isPersonalisableProduct=${personalised}&templateName=${templateName}&stringTemplatePath=components/athenaProductImageCarousel/athenaProductImageCarousel`,
      success: result => comp.updateImageSuccess(result, personalised),
      error: () => comp.updateImageError(productId, variation),
    });
  };

  var _throttle = function (type, name, comp) {
    comp = comp || $window;
    var running = false;
    var func = function () {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(function () {
        comp.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    comp.addEventListener(type, func);
  };

  comp.updateImageSuccess = function (result, personalised) {
    const parent = comp.element.parentNode;

    if (!parent) {
      $console.error('There is no parent for the athenaProductImageCarousel component.');
      return;
    }
    comp.element.outerHTML = result;
    comp.element = parent.querySelector(_selectors.component);

    personalised && app.publish(_channels.updateImageOverlayChannel);

    comp.init(comp.element, true);
  };

  comp.updateImageError = function (productId, variation) {
    $console.error(`Could not load product images. [productId=${productId}, variation=${variation}]`);
  };

  comp.navigate = function (index, firstLoad = false) {
    let announcementMessage;

    if (!comp.images[index]) {
      return;
    }

    if (comp.selection !== -1) {
      if (comp.thumbnailWrappers[comp.selection]) {
        comp.thumbnailWrappers[comp.selection].setAttribute(_attr.active, 'false');
      }
      if (comp.progressIndicators[comp.selection]) {
        comp.progressIndicators[comp.selection].setAttribute(_attr.active, 'false');
      }
    }

    comp.selection = index;
    if (comp.thumbnailWrappers[index]) {
      comp.thumbnailWrappers[index].setAttribute(_attr.active, 'true');
    }
    if (comp.progressIndicators[index]) {
      comp.progressIndicators[index].setAttribute(_attr.active, 'true');
    }

    if (document.dir === 'ltr') {
      comp.slider.style.left = `${index * -100}%`;
    } else {
      comp.slider.style.right = `${index * -100}%`
    }

    const {element, src, srcset, loaded} = comp.images[index];

    const type = element.tagName.toLowerCase();

    // only update if it's an image
    if (!loaded && type === 'img') {
      element.addEventListener('load', comp.imageLoaded);
      element.src = src;
      element.srcset = srcset;
    }

    let altText = comp.images[index].element.getAttribute(_attr.dataAlt);
    altText = altText ? '- ' + altText : '';

    const videoElement = comp.video;
    if (videoElement && videoElement !== 'undefined') {
      if (type === 'video') {
        comp.videoContainer.removeAttribute('aria-hidden');
        accessibilityFocusHelper.enableElement(videoElement);
        accessibilityFocusHelper.enableElement(comp.openTranscriptButton);

        videoElement.play();

        comp.zoomButton.style['visibility'] = 'hidden';

        comp.slider.addEventListener('transitionend', function setFocusOnVideoAfterScroll() {
          comp.videoContainer.focus();
          comp.slider.removeEventListener('transitionend', setFocusOnVideoAfterScroll);
        });

      } else {
        comp.videoContainer.setAttribute('aria-hidden', 'true');
        accessibilityFocusHelper.disableElement(videoElement);
        accessibilityFocusHelper.disableElement(comp.openTranscriptButton);

        videoElement.pause();

        comp.zoomButton.style['visibility'] = 'visible';

        // If video thumbnail is present then we need to map image index labels differently
        const imageIndex = index === 0 ? 1 : index;

        announcementMessage = `Showing image ${imageIndex} ${altText}`;
      }
    } else {
      announcementMessage = `Showing image ${index + 1} ${altText}`;
    }

    !firstLoad
    && announcementMessage
    && app.publish('accessibility/announce', 'assertive', announcementMessage);
  };

  comp.previous = function () {
    const index = comp.selection - 1;
    comp.navigate(index >= 0 ? index : comp.images.length - 1);
    app.publish('tracking/record', 'athenaProductImageCarousel', 'click', 'previous image');
  };

  comp.next = function () {
    const index = comp.selection + 1;
    comp.navigate(index < comp.images.length ? index : 0);
    app.publish('tracking/record', 'athenaProductImageCarousel', 'click', 'next image');
  };

  comp.previewPersonalisation = function (index) {
    comp.navigate(index);
  }

  comp.zoomClick = function () {
    const images = comp.images.map(({thumbnail, zoomedSrcset, src, type, element}) =>
      ({thumbnail, srcset: zoomedSrcset, src, type, alt: element.getAttribute(_attr.dataAlt)}))
      .filter(({type}) => type !== 'video');

    app.publish('productImageZoom/open', images, comp.selection);
    app.publish('tracking/record', 'athenaProductImageCarousel', 'open', 'zoom');
  };

  comp.thumbnailClick = function (ev) {
    comp.navigate(ev.currentTarget._index);
    app.publish(
      'tracking/record',
      'athenaProductImageCarousel',
      'click',
      'thumbnail',
      ev.currentTarget._index);
  };

  comp.videoPlay = function () {
    app.publish('tracking/record', 'athenaProductImageCarousel', 'video', 'play');
    app.publish('columbo/track', 'athenaProductImageCarousel', 'video', 'play');
  };

  comp.videoPause = function () {
    app.publish('tracking/record', 'athenaProductImageCarousel', 'video', 'pause');
    app.publish('columbo/track', 'athenaProductImageCarousel', 'video', 'pause');
  };

  comp.imageLoaded = function (ev) {
    const index = ev.currentTarget._index;

    if (comp.images[index].loaded) {
      return;
    }

    comp.images[index].loaded = true;
    comp.wrappers[index].style['background-image'] = '';
    comp.images[index].previewEl? comp.images[index].previewEl.setAttribute(_attr.hide, 'true') : false;
    comp.images[index].element? comp.images[index].element.setAttribute(_attr.hide, 'false') : false;

    // fix for 0px height when the images are updated before the low res images load
    comp.setThumbnailHeight();
  };

  comp.touchstart = function (ev) {
    _touch.history = [{
      x: ev.changedTouches[0].pageX,
      t: ev.timeStamp,
    }];
  };

  comp.touchmove = function (ev) {
    _touch.history.push({
      x: ev.changedTouches[0].pageX,
      t: ev.timeStamp,
    });

    if (_touch.history.length > _touch.historyMaxLength) {
      _touch.history.shift();
    }
  };

  comp.touchend = function () {
    // Last touch is unreliable, don't push to history
    var velX = 0;

    for (var index = 1; index < _touch.history.length; index++) {
      var deltaX = _touch.history[index].x - _touch.history[index - 1].x;
      var deltaT = _touch.history[index].t - _touch.history[index - 1].t;

      velX += deltaX / deltaT;
    }

    if (Math.abs(velX) < _touch.threshold) {
      return;
    }

    if (velX > 0) {
      comp.previous();
    } else {
      comp.next();
    }

    app.publish('columbo/track', 'athenaProductImageCarousel', 'swipe', 'carousel image');
  };

  return comp;
});
